import {
  Card,
  Input,
  Button,
  Radio,
  Divider,
  Flex,
  Row,
  Col,
  Drawer,
  Image,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setWebaseModalOpen } from "store/module/chain";
import { checkState, fetchRecordList, handleAnalysisApi } from "service/webase";
import { setIsLogin } from "store/module/global";
import dayjs from "dayjs";
import useItems from "antd/es/menu/hooks/useItems";
import shareImg from "assets/img/share.png";

const { TextArea } = Input;

const DivWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;

export default function HomePage() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [value, setValue] = useState(1);
  const [relateInfo, setRelateInfo] = useState("Soluer");
  const [shareLink, setShareLink] = useState("");
  const [extraInfo, setExtraInfo] = useState("");
  const [extraInfoPlacehoder, setExtraInfoPlaceholder] = useState("");
  const [recordList, setRecordList] = useState([]);
  const { isLogin } = useSelector((state) => ({
    isLogin: state.global.isLogin,
  }));
  const onChange = (e) => {
    let val = e.target.value;
    if (val == 2) {
      setRelateInfo("广场瞬间");
      setExtraInfoPlaceholder("\n瞬间标志内容和位置：xxx");
    } else {
      setRelateInfo("Soluer");
      setExtraInfoPlaceholder("");
    }
    setValue(val);
    setExtraInfo("");
  };
  const handleLogin = () => {
    console.log("hanlelogin");
    dispatch(setWebaseModalOpen(true));
  };
  const handleFocus = () => {
    return;
    if (extraInfo?.length > 0) {
      return;
    }
    let model = "本人链接：xxx\nSoler名称和位置：xxx";
    if (value == 2) {
      setExtraInfo(`${model}\n广场标志内容和位置：xxx`);
    } else {
      setExtraInfo(model);
    }
    console.log("focus");
  };
  const handleExtraInfo = (e) => {
    let val = e.target.value;
    setExtraInfo(val);
  };
  const handleAnalysis = async () => {
    const regexForActivity =
      /^https:\/\/w13\.soulsmile\.cn\/activity\/#\/voice\?postIdEcpt=[^&]+&picture=[^&]+$/;
    const regexForUser =
      /^https:\/\/w13\.soulsmile\.cn\/activity\/#\/web\/user\?targetUserIdEcpt=[^&]+&userIdEcpt=[^&]+&shareUserId=[^&]+&titleNum=\d+&sec=[^&]+$/;
    console.log(value, shareLink, extraInfo);
    if (shareLink.length <= 0 && extraInfo.length <= 0) {
      messageApi.error("内容为空");
      return;
    }
    if (shareLink.length > 0) {
      if (value == 1 && !regexForUser.test(shareLink)) {
        messageApi.error("Soluer链接错误");
        return;
      }
      if (value == 2 && !regexForActivity.test(shareLink)) {
        messageApi.error("瞬间链接错误");
        return;
      }
    }
    try {
      await handleAnalysisApi({
        type: value,
        relateInfo: shareLink,
        extraInfo,
      });
    } catch (error) {
      messageApi.error({
        type: "success",
        content: error,
      });
      return;
    }
    messageApi.info({
      type: "success",
      content: "提交成功，请稍候",
    });
    setShareLink("");
    setExtraInfo("");
  };
  const copyToClipboard = (content) => {
    navigator.clipboard
      .writeText(content)
      .then(() => {
        messageApi.info("复制成功");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };
  const handleDownload = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = "your-resource.m4a"; // 设置下载时的文件名
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };
  useEffect(async () => {
    let data = await checkState();
    console.log("data", data);
    if (data?.openId) {
      dispatch(setIsLogin(true));
      let recordList = await fetchRecordList();
      setRecordList(recordList);
    }
    setInterval(async () => {
      let recordList = await fetchRecordList();
      setRecordList(recordList);
    }, 5000);
  }, []);
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <a href="https://t.soulapp.cn/ZnE7zq">
          <img style={{ borderRadius: "2%" }} src={shareImg} />
        </a>
      </div>
      {/* <Drawer title="详情" placement="bottom"  onClose={() => setOpen(false)} open={open}>
        <div></div>
      </Drawer> */}
      {contextHolder}
      <DivWrapper>
        <Card
          title="soul音频资源解析"
          style={{
            width: 800,
          }}
        >
          <div>
            类型：
            <Radio.Group onChange={onChange} value={value}>
              <Radio value={1}>声音卡片</Radio>
              <Radio value={2}>广场瞬间</Radio>
            </Radio.Group>
          </div>
          <Input
            style={{ marginTop: "20px" }}
            placeholder={`${relateInfo}分享链接放在这里`}
            value={shareLink}
            onChange={(e) => setShareLink(e.target.value)}
          />
          <TextArea
            style={{ marginTop: "20px" }}
            rows={7}
            onFocus={handleFocus}
            onChange={handleExtraInfo}
            value={extraInfo}
            placeholder={`若Souler设置不可分享，需要关注Soler并填写辅助信息，模版——\n\n本人链接：xxx\nSoler名称和位置：xxx ${extraInfoPlacehoder} `}
          />
          {isLogin ? (
            <Button
              block
              style={{ marginTop: "20px" }}
              onClick={handleAnalysis}
              type="primary"
            >
              解析
            </Button>
          ) : (
            <Button
              block
              style={{ marginTop: "20px" }}
              onClick={handleLogin}
              danger
            >
              未登录，无法解析
            </Button>
          )}
          {isLogin ? <div>剩余积分：120</div> : ""}
        </Card>
      </DivWrapper>
      <Divider plain>解析记录查询（仅展示最新5条，包含最新提交）</Divider>
      {/* <div>
        <Row justify="center">
          <Col width="100%">
            {recordList.map((item, index) => (
              <Card
                key={item.id}
                title={
                  item.status == 0
                    ? "新提交"
                    : item.status == 1
                    ? "解析中"
                    : item.status == 2
                    ? "解析成功"
                    : "解析失败"
                }
                extra={<a href="#">详情</a>}
                style={{
                  width: 300,
                }}
              >
                {dayjs(item.createDate).format("YYYY-MM-DD HH:mm:ss")}
              </Card>
            ))}
          </Col>
        </Row>
      </div> */}

      <Flex gap="middle" vertical>
        <Flex vertical={true} align="center" wrap>
          {recordList.map((item, index) => (
            <Card
              key={item.updateTime}
              title={
                item.status == 0
                  ? "新提交"
                  : item.status == 1
                  ? "解析中"
                  : item.status == 2
                  ? "解析成功"
                  : "解析失败"
              }
              extra={<h3 onClick={() => setOpen(true)}>详情</h3>}
              style={{
                width: 400,
              }}
            >
              <div>
                提交时间：{dayjs(item.createTime).format("YYYY-MM-DD HH:mm:ss")}
              </div>
              <div>类型：{item.type == 1 ? "声音卡片" : "广场瞬间"}</div>
              {item?.relateInfo?.length > 0 ? (
                <div
                  title={item.relateInfo}
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  链接：{item.relateInfo}
                </div>
              ) : (
                ""
              )}
              <div>
                <Button
                  type="primary"
                  onClick={() => copyToClipboard(item.relateInfo)}
                  style={{ marginRight: "10px" }}
                >
                  复制链接查阅
                </Button>
                <Button
                  type="primary"
                  onClick={() => handleDownload(item.fileLink)}
                  disabled={item?.fileLink?.length <= 0 || !item.fileLink}
                >
                  资源下载
                </Button>
              </div>
            </Card>
          ))}
        </Flex>
      </Flex>
    </div>
  );
}
