import Icon from "components/Icon";
import React, { useEffect } from "react";
import { Row, Col, Badge, Button, message } from "antd";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { qiandaoApi, webaseGetBalance } from "service/webase";
import { setChainModalOpen } from "store/module/app";
import { setMintBlance, setWalletModalOpen } from "store/module/chain";
import { setIsLogin } from "store/module/global";
import { ethers } from "ethers";

import styled from "styled-components";
import ContractInfo from "assets/json/contractInfo";
import ABI from "assets/abi/test.json";
import { SXB } from "enums/otherEnums";
import { useEthers, useContractFunction } from "@usedapp/core";
import { useContract } from "context/ContractProvider";

const MyUl = styled.ul`
  .dCzUxY:hover:not([disabled]) {
    background: rgba(18, 18, 18, 0.04);
  }
  .eMUuwo {
    height: 1px;
    margin: 8px -8px px;
    width: calc(100% + 16px);
    background-color: rgba(18, 18, 18, 0.12);
  }
`;
export default function ProfilePopover({ setShowLan, closeProfileOpen }) {
  const {
    currentLan,
    isLogin,
    languageOptions,
    signUserId,
    account,
    chainType,
  } = useSelector(
    (state) => ({
      currentLan: state.global.currentLan,
      isLogin: state.global.isLogin,
      languageOptions: state.global.languageOptions,
      signUserId: state.chain.signUserId,
      account: state.chain.account,
      chainType: state.chain.chainType,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const contract = useContract();

  const { library } = useEthers();
  const logout = (e) => {
    e.preventDefault();
    if (!isLogin) {
      dispatch(setWalletModalOpen(true));
      return;
    }

    localStorage.clear();
    dispatch(setIsLogin(false));
    closeProfileOpen();
  };
  const switchProfilePage = (e) => {
    if (!isLogin) {
      dispatch(setWalletModalOpen(true));
      return;
    }
    e.preventDefault();
    if (isLogin) {
      navigate("/profile");
    } else {
      dispatch(setWalletModalOpen(true));
    }
    closeProfileOpen();
  };
  const qiandao = async () => {
    if (!isLogin) {
      dispatch(setWalletModalOpen(true));
      return;
    }
    if (chainType == "metamask") {
      if (!library) {
        return;
      }
      console.log(contract, contract.contract, contract.abi);
      const con = new ethers.Contract(
        contract.contract,
        contract.abi,
        library.getSigner()
      );
      try {
        await con.signIn();
      } catch (error) {
        if (error.reason) {
          message.error(error.reason);
        }
      }
      try {
        let balanceOfRes = await con.balanceOf(account, SXB);
        console.log("balanceOfRes", balanceOfRes.toNumber());
        dispatch(setMintBlance(balanceOfRes.toNumber()));
      } catch (error) {
        console.log("balanceof", error);
      }

      // console.log("balance", balance);
      // await send();
      // if (state.errorMessage) {
      //   message.error(state.errorMessage);
      //   return;
      // }

      // await window.ethereum.request({ method: "eth_requestAccounts" });
      // // 创建一个 ethers 提供的 provider
      // const provider = new ethers.providers.Web3Provider(window.ethereum);
      // // 从 provider 获取 signer
      // const signer = provider.getSigner();
      // // 使用 signer 而不是 provider 来创建合约实例，以便可以发送交易
      // const contract = new ethers.Contract(
      //   ContractInfo.Sepolia.contractAddress,
      //   ABI.abi,
      //   signer // 使用 signer 替换原来的 provider
      // );

      // try {
      //   await contract.signIn();
      // } catch (error) {
      //   if (error.reason) {
      //     message.error(error.reason);
      //   }
      // }
      // try {
      //   let balanceOfRes = await contract.balanceOf(account, SXB);
      //   console.log("balanceOfRes", balanceOfRes.toNumber());
      //   dispatch(setMintBlance(balanceOfRes.toNumber()));
      // } catch (error) {
      //   console.log("balanceof", error);
      // }
    } else if (chainType == "webase") {
      let resp = await qiandaoApi({
        signUserId,
      });
      if (resp.statusOK == true) {
        console.log("if");
        message.info("签到成功");
        let mintBalance = await webaseGetBalance({
          account,
          signUserId,
          appId: SXB,
        });
        // console.log(mintBalance);
        dispatch(setMintBlance(mintBalance[0]));
      } else {
        message.error(resp.message);
      }
    }
  };

  return (
    <div>
      <MyUl className="[&>:first-child]:rounded-t-xl [&>:last-child]:rounded-b-xl [&>:first-child>*:first-child]:rounded-t-xl [&>:last-child>*:last-child]:rounded-b-xl [&_[data-id=Item]]:border-0 [&_[data-id=Item]]:overflow-hidden rounded-none border-0 bg-inherit [&_[data-id=Item]]:rounded-none sc-122065ab-5 bIEFgx">
        <li className="w-full">
          <Link
            to={"#"}
            onClick={switchProfilePage}
            className="cursor-pointer no-underline disabled:pointer-events-none disabled:opacity-40 flex w-full border border-level-2 p-4 sc-122065ab-0 dCzUxY"
            data-id="Item"
          >
            <Icon id={"profilePerson"} style={{ marginRight: "10px" }} />
            <div
              className="flex flex-col justify-center order-3 overflow-hidden flex-auto items-start self-stretch sc-122065ab-3 eNViJe mr-0"
              data-id="ItemContent"
            >
              <span
                className="text-md leading-md font-semibold text-primary sc-122065ab-2 PPVQP"
                data-id="ItemTitle"
              >
                Profile
              </span>
            </div>
          </Link>
        </li>
        <li className="w-full" onClick={qiandao}>
          <div
            className="cursor-pointer no-underline disabled:pointer-events-none disabled:opacity-40 flex w-full border border-level-2 p-4 sc-122065ab-0 dCzUxY"
            data-id="Item"
          >
            <Icon id={"profilePerson"} style={{ marginRight: "10px" }} />
            <div
              className="flex flex-col justify-center order-3 overflow-hidden flex-auto items-start self-stretch sc-122065ab-3 eNViJe mr-0"
              data-id="ItemContent"
            >
              <span
                className="text-md leading-md font-semibold text-primary sc-122065ab-2 PPVQP"
                data-id="ItemTitle"
              >
                签到
              </span>
            </div>
          </div>
        </li>
        <li aria-hidden="true" className="eMUuwo" />
        <li className="w-full" onClick={() => setShowLan(true)}>
          <button
            className="items-center bg-inherit font-[inherit] text-[100%] flex border border-level-2 p-4 sc-122065ab-0 dCzUxY w-full"
            type="button"
            data-id="Item"
          >
            <Icon
              id={"world"}
              width="32"
              height="32"
              style={{ marginRight: "10px" }}
            />
            <div
              className="flex flex-col justify-center order-3 overflow-hidden flex-auto items-start self-stretch sc-122065ab-3 eNViJe mr-0"
              data-id="ItemContent"
            >
              <span
                className="text-md leading-md font-semibold text-primary sc-122065ab-2 PPVQP"
                data-id="ItemTitle"
              >
                Language
              </span>
            </div>
            <div className="flex flex-col order-5 ml-3 justify-center">
              <div className="flex items-center">
                <span
                  className="text-md leading-md text-secondary"
                  data-id="TextBody"
                >
                  {currentLan}
                </span>
                <Icon id={"rightRow"} />
              </div>
            </div>
          </button>
        </li>

        <li aria-hidden="true" className="eMUuwo" />
        <li className="w-full">
          <Link
            onClick={logout}
            to="#"
            className="cursor-pointer no-underline disabled:pointer-events-none disabled:opacity-40 flex w-full border border-level-2 p-4 sc-122065ab-0 dCzUxY"
            href="https://opensea.io/learn"
            rel=""
            target="_blank"
            data-id="Item"
          >
            <Icon
              id={"logout"}
              width="28"
              height="28"
              style={{ marginRight: "10px" }}
            />
            <div
              className="flex flex-col justify-center order-3 overflow-hidden flex-auto items-start self-stretch sc-122065ab-3 eNViJe mr-0"
              data-id="ItemContent"
            >
              <span
                className="text-md leading-md font-semibold text-primary sc-122065ab-2 PPVQP"
                data-id="ItemTitle"
              >
                Log Out
              </span>
            </div>
          </Link>
        </li>
      </MyUl>
    </div>
  );
}
