const cache = {
  key: "webase_",
  // Set cache (expire is the cache duration)
  set(key, value, expire) {
    key = this.getKey(key);
    let data = {
      expire: expire ? this.time() + Number(expire) : "",
      value,
    };
    if (typeof data === "object") {
      data = JSON.stringify(data);
    }
    try {
      window.localStorage.setItem(key, data);
    } catch (e) {
      return null;
    }
  },
  get(key) {
    key = this.getKey(key);
    try {
      const data = window.localStorage.getItem(key);
      if (!data) {
        return null;
      }
      const { value, expire } = JSON.parse(data);
      // console.log(`expire: ${expire} time: ${this.time()}`);
      if (expire && expire < this.time()) {
        window.localStorage.removeItem(key);
        return null;
      }
      return value;
    } catch (e) {
      return null;
    }
  },
  // Get current time
  time() {
    return Math.round(new Date().getTime() / 1000);
  },
  remove(key) {
    key = this.getKey(key);
    window.localStorage.removeItem(key);
  },
  getKey(key) {
    return this.key + key;
  },
};
export default cache;
