import axios from "axios";
import { merge, cloneDeep } from "lodash";

export class Axios {
  constructor(config) {
    this.config = config;
    this.options = config.requestOptions;
    this.axiosInstance = axios.create(config);
    this.setupInterceptors();
  }

  getAxiosInstance() {
    return this.axiosInstance;
  }

  setupInterceptors() {
    if (!this.config.axiosHooks) {
      return;
    }
    const {
      requestInterceptorsHook,
      requestInterceptorsCatchHook,
      responseInterceptorsHook,
      responseInterceptorsCatchHook,
    } = this.config.axiosHooks;

    this.axiosInstance.interceptors.request.use(
      (config) => {
        this.addCancelToken(config);
        // console.log(
        //   "this.axiosInstance.interceptors.request.use config",
        //   config
        // );
        if (typeof requestInterceptorsHook === "function") {
          config = requestInterceptorsHook(config);
        }
        return config;
      },
      (err) => {
        // console.log("this.axiosInstance.interceptors.request.use err", err);
        if (typeof requestInterceptorsCatchHook === "function") {
          console.log("err axios.js", err);
          requestInterceptorsCatchHook(err);
        }
        return Promise.reject(err);
      }
    );

    this.axiosInstance.interceptors.response.use(
      (response) => {
        this.removeCancelToken(response.config.url);
        if (typeof responseInterceptorsHook === "function") {
          response = responseInterceptorsHook(response);
          // console.log(
          //   "this.axiosInstance.interceptors.response.use response if",
          //   response
          // );
        }
        // console.log(
        //   "this.axiosInstance.interceptors.response.use response",
        //   response
        // );
        return response;
      },
      (err) => {
        // console.log("this.axiosInstance.interceptors.response.use err", err);
        if (err.code !== "ECONNABORTED" && err.code !== "ERR_NETWORK") {
          this.removeCancelToken(err.config.url);
        }
        if (err.code === "ECONNABORTED" || err.code === "ERR_NETWORK") {
          return new Promise((resolve) => setTimeout(resolve, 500)).then(() =>
            this.retryRequest(err)
          );
        }
        return Promise.reject(err);
      }
    );
  }

  addCancelToken(config) {
    const { ignoreCancelToken } = config.requestOptions;
    if (!ignoreCancelToken) {
      // Add cancel token logic here
    }
  }

  removeCancelToken(url) {
    // Remove cancel token logic here
  }

  retryRequest(error) {
    const config = error.config;
    const { retryCount, isOpenRetry } = config.requestOptions;
    if (!isOpenRetry || config.method.toUpperCase() === "POST") {
      return Promise.reject(error);
    }
    config.retryCount = config.retryCount || 0;
    if (config.retryCount >= retryCount) {
      return Promise.reject(error);
    }
    config.retryCount++;
    return this.axiosInstance.request(config);
  }

  get(config, options) {
    return this.request({ ...config, method: "GET" }, options);
  }

  post(config, options) {
    return this.request({ ...config, method: "POST" }, options);
  }

  request(config, options) {
    const opt = merge({}, this.options, options);
    const axiosConfig = {
      ...cloneDeep(config),
      requestOptions: opt,
    };
    const { urlPrefix } = opt;
    if (urlPrefix) {
      axiosConfig.url = `${urlPrefix}${config.url}`;
    }
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .request(axiosConfig)
        .then((res) => {
          // console.log("res", res);
          resolve(res);
        })
        .catch((err) => {
          console.log("err", err);
          reject(err);
        });
    });
  }
}
