// src/features/counter/counterSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

export const createAppSlice = createSlice({
  name: "createapp",
  initialState: {
    processPictureList: [],
    metaProcessVideo: "",
    language: "en",
    coverUrl: "",
    webaseAddress: "",
    sepoliaAddress: "",
    downloadUrl: "",
  },
  reducers: {
    addProcessItem(state, { payload }) {
      state.processPictureList.splice(payload.index, 0, {
        uuid: uuidv4(),
        contentMode: "img",
      });
    },
    removeProcessItem(state, { payload }) {
      state.processPictureList = state.processPictureList.filter(
        (e) => e.uuid != payload.uuid
      );
    },
    updateProcessItem(state, { payload }) {
      const index = state.processPictureList.findIndex(
        (e) => e.uuid == payload.uuid
      );
      console.log("index", index, payload);
      if (index != -1) {
        state.processPictureList[index] = payload;
      }
    },
    setMetaProcessVideo(state, { payload }) {
      state.metaProcessVideo = payload;
    },
    setLanguage(state, { payload }) {
      state.language = payload;
    },
    setCoverUrl(state, { payload }) {
      state.coverUrl = payload;
    },
    setWebaseAddress(state, { payload }) {
      state.webaseAddress = payload;
    },
    setSepoliaAddress(state, { payload }) {
      state.sepoliaAddress = payload;
    },
    updateDownloadUrl(state, { payload }) {
      state.downloadUrl = payload;
    },
  },
});

export const {
  addProcessItem,
  removeProcessItem,
  updateProcessItem,
  setMetaProcessVideo,
  setLanguage,
  setCoverUrl,
  setWebaseAddress,
  setSepoliaAddress,
  updateDownloadUrl,
} = createAppSlice.actions;

export default createAppSlice.reducer;
