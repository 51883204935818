import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setChainModalOpen, setSearchInfo } from "store/module/app";
import { Col, Row, Select, Modal, message } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useEthers } from "@usedapp/core";
import styled from "styled-components";

import BackWallet from "assets/img/wallet/backWallet.webp";
import WebLogo from "assets/img/logos/favicon.svg";
import WeBaseLogo from "assets/img/logos/webase.svg";
import MetamaskLogo from "assets/img/logos/metamask.svg";

import Icon from "components/Icon";
import WeBaseModal from "./components/WeBaseModal";
import {
  setAccount,
  setChainId,
  setWalletModalOpen,
  setWebaseModalOpen,
} from "store/module/chain";
import { checkState, getSessionId } from "service/webase";
import cache from "utils/cache";
import { TOKEN_KEY } from "enums/cacheEnums";
import { setIsLogin } from "store/module/global";

const CustomModal = styled(Modal)`
  .ant-modal-content {
    padding: 0;
  }
`;

export default function ConnectWallet() {
  const [code, setCode] = useState("");
  const { walletModalOpen, webaseModalOpen } = useSelector((state) => ({
    walletModalOpen: state.chain.walletModalOpen,
    webaseModalOpen: state.chain.webaseModalOpen,
  }));
  const { account, deactivate, activateBrowserWallet, chainId } = useEthers();
  const dispatch = useDispatch();
  const clickWebaseOption = async () => {
    let token = cache.get(TOKEN_KEY);
    console.log("connectwallet token ", token);
    if (token) {
      console.log("connectwallet token if ");
      checkState().then((res) => {
        if (res) {
          window.location.reload();
        } else {
          cache.remove(TOKEN_KEY);
          message.info("登录过期，请重新登录");
          dispatch(setWebaseModalOpen(true));
        }
      });
    } else {
      console.log("connectwallet else ");
      dispatch(setWebaseModalOpen(true));
    }
  };
  const connectMetamsk = () => {
    activateBrowserWallet();
    dispatch(setWalletModalOpen(false));
    dispatch(setChainId(chainId));
  };
  return (
    <CustomModal
      open={walletModalOpen}
      onCancel={() => dispatch(setWalletModalOpen(false))}
      footer={null}
      width={"600px"}
    >
      <div className="relative">
        {/* 1 */}
        <div className="absolute h-40 w-full">
          <img
            alt=""
            fetchpriority="high"
            decoding="async"
            data-nimg="fill"
            src={BackWallet}
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              inset: 0,
              objectFit: "cover",
              color: "transparent",
            }}
          />
        </div>
        {/* 2 */}
        <div className="flex flex-col w-full bg-base-2 px-6 pb-6 pt-[74px]">
          <div>
            <div className="relative box-content h-[60px] w-[60px] rounded-full border-[3px] border-base-2 bg-base-2">
              <img
                alt=""
                fetchpriority="high"
                decoding="async"
                data-nimg="fill"
                src={WebLogo}
                style={{
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  inset: 0,
                  color: "transparent",
                }}
              />
            </div>
          </div>
          <div className="mb-2 mt-6 w-full">
            <div className="flex flex-col w-full">
              <div className="flex items-center gap-2">
                <h2
                  className="font-semibold leading-heading-sm text-heading-xs leading-heading-xs lg:text-heading-sm lg:leading-heading-sm"
                  data-id="TextHeading"
                >
                  Connect to AJTOP
                </h2>
              </div>
              <form
                className="sc-beff130f-0 sc-3f00a2c7-0 hksMfk mt-4 flex w-full gap-3"
                style={{ alignItems: "center" }}
              >
                <div className="flex flex-col w-full">
                  <div className="flex flex-col">
                    <div className="flex flex-col">
                      <div className="flex items-center">
                        <span
                          style={{
                            position: "absolute",
                            border: 0,
                            width: 1,
                            height: 1,
                            padding: 0,
                            margin: "-1px",
                            overflow: "hidden",
                            clip: "rect(0px, 0px, 0px, 0px)",
                            whiteSpace: "nowrap",
                            overflowWrap: "normal",
                          }}
                        >
                          <label
                            className="text-md leading-md font-semibold w-full"
                            htmlFor="email"
                            data-id="TextBody"
                          >
                            Email
                          </label>
                        </span>
                      </div>
                    </div>
                    <div className="flex h-12 w-full items-center rounded-xl p-3 text-md border border-level-2 transition duration-200 hover:border-level-3 focus-within:shadow-none focus-within:hover:shadow-none focus-within:border-level-3 focus:outline-none flex-1 bg-white py-2.5 dark:bg-component-gray-1">
                      <input
                        aria-invalid="false"
                        autoComplete="email"
                        placeholder="Email"
                        type="email"
                        id="email"
                        name="email"
                        className="w-full border-0 bg-transparent outline-none placeholder:text-secondary [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none min-h-[26px] text-[16px] leading-[26px] sm:font-[inherit] sm:leading-[inherit] sm:text-[inherit]"
                        defaultValue=""
                      />
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  disabled=""
                  className="inline-flex items-center justify-center whitespace-nowrap transition duration-200 text-md leading-md font-semibold bg-blue-3 text-white hover:bg-blue-2 gap-3 rounded-xl py-3 px-3 disabled:pointer-events-none disabled:opacity-40 pointer-events-none opacity-40 flex-none self-start"
                  data-id="Button"
                  aria-label="Login"
                  icon="arrow_forward"
                >
                  <ArrowRightOutlined
                    style={{
                      width: "24px",
                      height: "24px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  />
                </button>
              </form>
              <span
                className="text-xs leading-xs text-secondary mt-4"
                data-id="TextBody"
              >
                By continuing you agree to our{" "}
                <a
                  className="no-underline font-semibold cursor-pointer hover:text-interactive-secondary-hover disabled:pointer-events-none disabled:opacity-40 text-secondary"
                  href="/tosw"
                  target="_blank"
                >
                  Terms
                </a>{" "}
                &amp;{" "}
                <a
                  className="no-underline font-semibold cursor-pointer hover:text-interactive-secondary-hover disabled:pointer-events-none disabled:opacity-40 text-secondary"
                  href="/privacy"
                  target="_blank"
                >
                  Privacy Policy
                </a>{" "}
                and Privy's{" "}
                <a
                  className="no-underline font-semibold cursor-pointer hover:text-interactive-secondary-hover disabled:pointer-events-none disabled:opacity-40 text-secondary"
                  href="https://www.privy.io/user-terms-of-service"
                  rel="nofollow noopener"
                  target="_blank"
                >
                  Terms
                </a>
                .
              </span>
            </div>
          </div>
        </div>
        {/* 3 */}
        <div class="flex mt-[-15px] w-full items-center">
          <div
            data-orientation="horizontal"
            role="none"
            class="bg-border-1 h-[1px] w-full flex-1"
          ></div>
          <span
            class="text-xs leading-xs font-semibold text-secondary flex-none rounded-lg border border-level-1 bg-elevation-1 p-2 py-1.5 uppercase opacity-80"
            data-id="TextBody"
          >
            Or
          </span>
          <div
            data-orientation="horizontal"
            role="none"
            class="bg-border-1 h-[1px] w-full flex-1"
          ></div>
        </div>
        {/* 4 */}
        <div className="mt-2 w-full px-2">
          <div className="sc-beff130f-0 sc-86d90756-0 hksMfk iioorp">
            <ul className="[&>:first-child]:rounded-t-xl [&>:last-child]:rounded-b-xl [&>:first-child>*:first-child]:rounded-t-xl [&>:last-child>*:last-child]:rounded-b-xl [&_[data-id=Item]]:border-0 [&_[data-id=Item]]:overflow-hidden rounded-none border-0 bg-inherit [&_[data-id=Item]]:rounded-lg">
              <li className="w-full" onClick={clickWebaseOption}>
                <button
                  className="items-center bg-inherit font-[inherit] text-[100%] flex border border-level-2 p-4 cursor-pointer hover:bg-component-gray-1 active:bg-component-gray-2 rounded-lg w-full"
                  type="button"
                  data-id="Item"
                >
                  <img
                    className="overflow-hidden order-2 mr-4 shrink-0 self-center text-primary"
                    size={30}
                    data-id="ItemAvatar"
                    alt="MetaMask"
                    src={WeBaseLogo}
                    style={{ width: 30, height: 30 }}
                  />
                  <div
                    className="flex flex-col justify-center order-3 mr-4 overflow-hidden flex-auto items-start self-stretch"
                    data-id="ItemContent"
                  >
                    <span
                      className="text-md leading-md font-semibold text-primary"
                      data-id="ItemTitle"
                    >
                      WeBase
                    </span>
                  </div>
                  <div className="flex flex-col order-4 max-w-[40%] overflow-hidden text-right flex-[0_0_auto] justify-center self-stretch">
                    <div className="flex flex-col justify-center items-center rounded-md bg-component-gray-1 p-2 capitalize">
                      <span
                        className="text-xs leading-xs font-semibold text-secondary opacity-80"
                        data-id="TextBody"
                      >
                        China
                      </span>
                    </div>
                  </div>
                </button>
              </li>
              <li className="w-full">
                <button
                  className="items-center bg-inherit font-[inherit] text-[100%] flex border border-level-2 p-4 cursor-pointer hover:bg-component-gray-1 active:bg-component-gray-2 rounded-lg w-full"
                  type="button"
                  data-id="Item"
                >
                  <img
                    className="overflow-hidden order-2 mr-4 shrink-0 self-center text-primary"
                    size={30}
                    data-id="ItemAvatar"
                    alt="Coinbase Wallet"
                    src="https://mmbiz.qpic.cn/sz_mmbiz_jpg/gLMzPleWPibQpws39V9bBJ9BlQqYmFkLjGyhy9FS8w35bvJstttriciaLicnXwd1ZnPQe8HmibWaMGh1Wu6erhgsHEw/0?wx_fmt=webp&from=appmsg"
                    style={{ width: 30, height: 30 }}
                  />
                  <div
                    className="flex flex-col justify-center order-3 mr-4 overflow-hidden flex-auto items-start self-stretch"
                    data-id="ItemContent"
                  >
                    <span
                      className="text-md leading-md font-semibold text-primary"
                      data-id="ItemTitle"
                    >
                      Coinbase Wallet
                    </span>
                  </div>
                </button>
              </li>
              <li className="w-full">
                <button
                  className="items-center bg-inherit font-[inherit] text-[100%] flex border border-level-2 p-4 cursor-pointer hover:bg-component-gray-1 active:bg-component-gray-2 rounded-lg w-full"
                  type="button"
                  data-id="Item"
                >
                  <img
                    className="overflow-hidden order-2 mr-4 shrink-0 self-center text-primary"
                    size={30}
                    data-id="ItemAvatar"
                    alt="WalletConnect"
                    src="https://mmbiz.qpic.cn/sz_mmbiz_jpg/gLMzPleWPibQpws39V9bBJ9BlQqYmFkLjj3iax0LBLAFkrWOE79kblxKI7OEiaqOJ36fx0hwBevSXeiaAzl1TKic7Zw/0?wx_fmt=webp&from=appmsg"
                    style={{ width: 30, height: 30 }}
                  />
                  <div
                    className="flex flex-col justify-center order-3 mr-4 overflow-hidden flex-auto items-start self-stretch"
                    data-id="ItemContent"
                  >
                    <span
                      className="text-md leading-md font-semibold text-primary"
                      data-id="ItemTitle"
                    >
                      WalletConnect
                    </span>
                  </div>
                </button>
              </li>
            </ul>
            <div className="sticky bottom-0 w-full rounded-lg bg-elevation-2 pb-2">
              <button
                className="items-center font-[inherit] text-[100%] flex border-level-2 cursor-pointer border-0 rounded-lg bg-elevation-2 p-4 hover:bg-component-gray-1 active:bg-component-gray-1 w-full"
                type="button"
                data-id="Item"
                data-testid="view-all-wallets"
              >
                <div
                  className="flex flex-col justify-center items-center overflow-hidden order-2 mr-4 shrink-0 self-center text-primary rounded-lg bg-component-gray-1"
                  data-id="ItemAvatar"
                  style={{ width: 30, height: 30 }}
                >
                  <Icon id={"more"} />
                </div>
                <div
                  className="flex flex-col justify-center order-3 mr-4 overflow-hidden flex-auto items-start self-stretch"
                  data-id="ItemContent"
                >
                  <span
                    className="text-md leading-md font-semibold text-primary"
                    data-id="ItemTitle"
                  >
                    More
                  </span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  );
}
