// src/features/counter/counterSlice.js
import { createSlice } from "@reduxjs/toolkit";

export const chainSlice = createSlice({
  name: "chain",
  initialState: {
    contractArr: [
      {
        name: "Sepolia",
        address: "0xBa5FcfE22820b1C43ad5232250AcD450e9640401",
      },
    ],
    currentApp: {
      id: "",
    },
    currentContractAddress: "",
    account: "",
    balance: "0",
    mintBalance: 0,
    chainType: "",
    signUserId: "",
    chainId: 0,
    walletModalOpen: false,
    webaseModalOpen: false,
    accountOwnerList: [],
  },
  reducers: {
    setAccount(state, { payload }) {
      state.account = payload;
    },
    setBalance(state, { payload }) {
      state.balance = payload;
    },
    setChainType(state, { payload }) {
      state.chainType = payload;
      // localStorage.setItem("chain_type", payload);
    },
    setSignUserId(state, { payload }) {
      state.signUserId = payload;
    },
    setWalletModalOpen(state, { payload }) {
      state.walletModalOpen = payload;
    },
    setWebaseModalOpen(state, { payload }) {
      state.webaseModalOpen = payload;
    },
    setChainId(state, { payload }) {
      state.chainId = payload;
    },
    setCurrentContractAddress(state, { payload }) {
      state.currentContractAddress = payload;
    },
    setMintBlance(state, { payload }) {
      state.mintBalance = payload;
    },
    setAccountOwnerList(state, { payload }) {
      state.accountOwnerList = payload;
    },
    updateAccountOwnerList(state, { payload }) {
      const index = state.accountOwnerList.findIndex((e) => e.id != payload.id);
      if (index != -1) {
        console.log("chain.js", payload);
        state.accountOwnerList[index] = payload;
      }
    },
  },
});

export const {
  setAccount,
  setBalance,
  setChainType,
  setSignUserId,
  setWalletModalOpen,
  setWebaseModalOpen,
  setChainId,
  setCurrentContractAddress,
  setMintBlance,
  setAccountOwnerList,
  updateAccountOwnerList,
} = chainSlice.actions;

export default chainSlice.reducer;
