let ContractInfo = {
  WeBase: {
    contractAddress: "0x8d51e5de73956126e0cc05038ed93f3569b1e284", // 软件安装合约
    chainId: -100,
  },
  Sepolia: {
    contractAddress: "0xF707F53A2D115eCcC312963Ca54941D76dF80c26", //
    chainId: 11115555,
  },
  11115555: {
    contractAddress: "0x8d51e5de73956126e0cc05038ed93f3569b1e284", //
    chainId: 11115555,
  },
};

export default ContractInfo;
