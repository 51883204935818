import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "components/Navbar/index";
import "./style.css";

export default function MainLayout() {
  return (
    <div className="flex flex-col sc-918288ec-0" style={{ minHeight: "100%" }}>
      {/* <Navbar /> */}
      <Outlet />
    </div>
  );
}
