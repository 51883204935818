import { configureStore } from "@reduxjs/toolkit";
import appReducer from "store/module/app.js";
import chainReducer from "store/module/chain";
import globalReducer from "store/module/global";
import createAppReducer from "store/module/createapp";
import adminReducer from "store/module/admin";
import createCollectReducer from "store/module/createcollect";

export const store = configureStore({
  reducer: {
    app: appReducer,
    chain: chainReducer,
    global: globalReducer,
    createapp: createAppReducer,
    admin: adminReducer,
    createcollect: createCollectReducer,
  },
});
