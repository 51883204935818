// src/features/counter/counterSlice.js
import { createSlice } from "@reduxjs/toolkit";

export const globalSlice = createSlice({
  name: "global",
  initialState: {
    languageOptions: [
      {
        value: "en",
        label: "English",
        checked: true,
      },
      {
        value: "zh",
        label: "中文",
      },
    ],
    currentLan: "en",
    isLogin: false,
    globalContract: {}, //全局合约实例对象集
    commnetDrawer: false,
    collectItemDrawer: false,
    currentCollectInfo: "", // 藏品信息
    currentCollectItem: "", //当前藏品item
    collectScanQrOpen: false,
  },
  reducers: {
    updateCurrentLanguage(state, { payload }) {
      state.languageOptions.map((e) => {
        if (e.value == payload) {
          e.checked = true;
          state.currentLan = payload;
        } else {
          e.checked = false;
        }
        return e;
      });
    },
    setIsLogin(state, { payload }) {
      state.isLogin = payload;
    },
    setCommnetDrawer(state, { payload }) {
      state.commnetDrawer = payload;
    },
    setCollectItemDrawer(state, { payload }) {
      state.collectItemDrawer = payload;
    },
    updateCurrentCollectInfo(state, { payload }) {
      state.currentCollectInfo = payload;
    },
    setGlobalContract(state, { payload }) {
      state.globalContract = payload;
    },
    setCurrentCollectItem(state, { payload }) {
      state.currentCollectItem = payload;
    },
    setCollectScanQrOpen(state, { payload }) {
      state.collectScanQrOpen = payload;
    },
  },
});

export const {
  updateCurrentLanguage,
  setIsLogin,
  setCommnetDrawer,
  setCollectItemDrawer,
  updateCurrentCollectInfo,
  setGlobalContract,
  setCurrentCollectItem,
  setCollectScanQrOpen,
} = globalSlice.actions;

export default globalSlice.reducer;
