import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row, Select, Modal, Space, Button, Popover } from "antd";
import { setChainModalOpen, setSearchInfo } from "store/module/app";
import qrcode from "assets/img/qrcode.jpg";
import service from "service/request";
import {
  Mainnet,
  DAppProvider,
  useEtherBalance,
  useEthers,
  Config,
  Goerli,
  useContractFunction,
} from "@usedapp/core";
import {
  setAccount,
  setChainId,
  setChainType,
  setSignUserId,
  setWalletModalOpen,
  setWebaseModalOpen,
} from "store/module/chain";
import { setIsLogin } from "store/module/global";
import { checkState, getSessionId } from "service/webase";
import cache from "utils/cache";
import { TOKEN_KEY } from "enums/cacheEnums";

export default function WeBaseModal() {
  const { account, deactivate, activateBrowserWallet, chainId } = useEthers();
  const [code, setCode] = useState("");

  const { webaseModalOpen } = useSelector((state) => ({
    webaseModalOpen: state.chain.webaseModalOpen,
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    async function a() {
      let { code: codeAlias, token } = await getSessionId();
      console.log("webase change modal open");
      cache.set(TOKEN_KEY, token, 1000 * 59 * 60 * 3);
      setCode(codeAlias);
      let intervalId = setInterval(async () => {
        console.log("interval run");

        let data = await checkState();
        if (data?.openId) {
          dispatch(setWebaseModalOpen(false));
          clearInterval(intervalId);
          dispatch(setIsLogin(true));
        }
      }, 5000);
    }
    if (webaseModalOpen) {
      a();
    }
  }, [webaseModalOpen]);
  return (
    <Modal
      open={webaseModalOpen}
      onCancel={() => dispatch(setWebaseModalOpen(false))}
      footer={null}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        <p>
          扫码关注公众号后，发送
          <span style={{ fontSize: "24px", fontWeight: "500" }}>{code}</span>
          进行登录
        </p>

        <img src={qrcode} />
      </div>
    </Modal>
  );
}
