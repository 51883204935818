// src/features/counter/counterSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

export const createCollectSlice = createSlice({
  name: "createcollect",
  initialState: {
    readyAddContractAddress: "",
    createCollectOpen: false, //创建藏品modal AddCollectDrawer
    createCollectItemOpen: false, //创建藏品item
  },
  reducers: {
    setReadyAddContractAddress(state, { payload }) {
      state.readyAddContractAddress = payload;
    },
    setCreateCollectOpen(state, { payload }) {
      state.createCollectOpen = payload;
    },
    setCreateCollectItemOpen(state, { payload }) {
      state.createCollectItemOpen = payload;
    },
  },
});

export const {
  setReadyAddContractAddress,
  setCreateCollectOpen,
  setCreateCollectItemOpen,
} = createCollectSlice.actions;

export default createCollectSlice.reducer;
