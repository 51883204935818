import ContractInfo from "assets/json/contractInfo";
import { request } from "utils/request";

import WeBaseABI from "assets/abi/webase.json";
import CommonCollect from "assets/abi/commonCollection.json";
import getFunAbi from "utils/abi";
import { DEFAULT_SIGN_USER_ID } from "enums/chainEnums";

let obj = {
  groupId: "1",
  contractAddress: ContractInfo.WeBase.contractAddress,
  contractPath: "/",
  contractName: "Market",
  useAes: false,
  useCns: false,
  cnsName: "",
};
export function getSessionId(params) {
  return request.get({
    url: "/mp/getSessionId",
    params,
  });
}

export function checkState(params) {
  return request.get({
    url: "/mp/login",
    params,
  });
}

export function fetchRecordList(params) {
  return request.get({
    url: "/mp/fetchRecordList",
    params,
  });
}

export function handleAnalysisApi(params) {
  return request.post({
    url: "/mp/handleAnalysis",
    params,
  });
}

// handlewithsign
export function handleWithSign(params) {
  return request.post({
    url: "/webase/handleWithSign",
    params,
  });
}
export function webaseGetBalance(params) {
  return request.post({
    url: "/webase/handleWithSign",
    params: {
      ...obj,
      contractAbi: getFunAbi("balanceOf"),
      signUserId: params.signUserId ?? DEFAULT_SIGN_USER_ID,
      funcName: "balanceOf",
      funcParam: [params.account, params.appId],
    },
  });
}

export function webaseBuyItem(params) {
  return request.post({
    url: "/webase/handleWithSign",
    params: {
      ...obj,
      signUserId: params.signUserId,
      funcName: "buyItem",
      contractAbi: getFunAbi("buyItem"),
      funcParam: [params.appId, 1],
    },
  });
}

export function webaseMint(params) {
  return request.post({
    url: "/webase/handleWithSign",
    params: {
      ...obj,
      signUserId: params.signUserId,
      funcName: "mint",
      contractAbi: getFunAbi("mint"),
      funcParam: [params.appId, params.mintCount, ""],
    },
  });
}

export function webaseListItem(params) {
  return request.post({
    url: "/webase/handleWithSign",
    params: {
      ...obj,
      signUserId: params.signUserId,
      funcName: "listItem",
      contractAbi: getFunAbi("listItem"),
      funcParam: [params.appId, params.saleCount],
    },
  });
}

export function webaseTotalSupply(params) {
  return request.post({
    url: "/webase/handleWithSign",
    params: {
      ...obj,
      signUserId: params.signUserId,
      funcName: "tokenSupply",
      contractAbi: getFunAbi("tokenSupply"),
      funcParam: [params.appId],
    },
  });
}

export function webaseApproval(params) {
  return request.post({
    url: "/webase/handleWithSign",
    params: {
      ...obj,
      contractAbi: getFunAbi("approveTokenId"),
      signUserId: params.signUserId,
      funcName: "approveTokenId",
      funcParam: [params.appId, params.ownerAddress],
    },
  });
}

export function qiandaoApi(params) {
  return request.post({
    url: "/webase/handleWithSign",
    params: {
      ...obj,
      contractAbi: getFunAbi("signIn"),
      signUserId: params.signUserId,
      funcName: "signIn",
      funcParam: [],
    },
  });
}

export function buyCollection(params) {
  return request.post({
    url: "/webase/handleWithSign",
    params: {
      ...obj,
      contractAbi: getFunAbi("buyCollection"),
      signUserId: params.signUserId,
      funcName: "buyCollection",
      funcParam: [params.contractAddress, params.buyer, params.tokenId],
    },
  });
}
export function approvalAppOwner(params) {
  return request.post({
    url: "/webase/handleWithSign",
    params: {
      ...obj,
      contractAbi: getFunAbi("setCollectOwner"),
      signUserId: params.signUserId,
      funcName: "setCollectOwner",
      funcParam: [params.contractAddress, params.appOwner],
    },
  });
}

export function transferCollection(params) {
  return request.post({
    url: "/webase/handleWithSign",
    params: {
      ...obj,
      contractAbi: getFunAbi("transferCollection"),
      signUserId: params.signUserId,
      funcName: "transferCollection",
      funcParam: [
        params.contractAddress,
        params.owner,
        params.to,
        params.tokenId,
      ],
    },
  });
}

export function mintRewardToken(params) {
  return request.post({
    url: "/webase/handleWithSign",
    params: {
      ...obj,
      contractAbi: getFunAbi("mintRewardToken"),
      signUserId: params.signUserId,
      funcName: "mintRewardToken",
      funcParam: [params.account, params.tokenId, params.amount, ""],
    },
  });
}

// eventlogs
export function eventLogs(params) {
  return request.post({
    url: "/webase/eventLogs",
    params: {
      ...obj,
      contractAbi: WeBaseABI["abiInfo"],
      fromBlock: 1,
      toBlock: -1,
      topics: {
        eventName: params.eventName,
        indexed1: params.indexed1,
        indexed2: params.indexed2,
      },
    },
  });
}

// eventlogs
export function deployWithSign(params) {
  return request.post({
    url: "/webase/deployWithSign",
    params: {
      abiInfo: CommonCollect["abiInfo"],
      bytecodeBin: CommonCollect["bytecodeBin"],
      signUserId: params.signUserId,
      funcParam: params.funcParam,
      groupId: 1,
    },
  });
}
