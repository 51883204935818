import React, { useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";

import openseaLogo from "assets/img/logos/self-logo.png";
import styled from "styled-components";
import "./style.css";
import ConnectWallet from "components/ConnectWallet";
import { useTranslation } from "react-i18next";

import { Button, Popover, Anchor, Drawer } from "antd";
import ProfilePopover from "components/ProfilePopover";
import ChooseLanguage from "components/ChooseLanguage";
import { setWalletModalOpen, setWebaseModalOpen } from "store/module/chain";
import { quickSearchApp } from "service/app";

import { isMobile, isBrowser } from "react-device-detect";
import MySearch from "./components/MySearch";
import Icon from "components/Icon";
import WalletInfoPopover from "./components/WalletInfoPopover";
import { Link } from "react-router-dom";
import CreatePopover from "components/Popover/CreatePopover";

const SelfDiv = styled.div`
  /* 最外层 */
  --transition-curve: cubic-bezier(0.05, 0, 0.2, 1);
  transition: background-color 0.2s var(--transition-curve),
    box-shadow 0.2s var(--transition-curve), color 0.2s var(--transition-curve),
    transform 0.4s var(--transition-curve);
  border-bottom: none;
  max-width: 100vw;
  height: 72px;
  top: 0px;
  position: sticky;
  z-index: 150;
  background-color: rgb(255, 255, 255, 1);
  /* nav */
  .Navbar--main {
    display: flex;
    height: 100%;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .jBeUAx {
    -webkit-box-align: center;
    align-items: center;
    height: 100%;
  }
  /* nav 1 */

  .eqsTJA {
    padding-right: 0px;
    display: flex;
  }
  @media screen and (min-width: 768px) {
    .eqsTJA {
      padding-right: 24px;
    }
  }
  @media screen and (min-width: 1024px) {
    .eqsTJA {
      display: flex;
    }
  }

  /* nav 1-1-1 */
  .evBMDZ {
    height: 40px;
    width: 40px;
  }

  /* nav 1-2-1 */
  .eoiwrf {
    height: 32px;
  }
  /* 1-2-2 */
  .bYWTiF {
    white-space: nowrap;
    background-color: transparent;
    color: #000;
  }

  /* nav 2 */
  .cnFHCH {
    position: relative;
    inset: auto;
    height: 100%;
    pointer-events: all;
    flex: 1 1 16px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }

  /* nav 2-1 */

  @media screen and (min-width: 768px) {
    .bzccwp {
      max-width: 100%;
    }
  }
  @media screen and (min-width: 1200px) {
    .bzccwp {
      max-width: 480px;
    }
  }

  .bzccwp {
    width: 100%;
  }
  /* input 宽度 */
  .kUjxuC {
    width: 100%;
  }
  /* */
  @media (min-width: 1024px) {
    .BWpzy {
      margin-right: 12px;
    }
  }
  .BWpzy {
    line-height: 26px;
    padding: 8px;
    transition: background-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s,
      border-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s;
    border-color: transparent;
    background-color: rgba(18, 18, 18, 0.04);
  }
  .BWpzy:hover,
  .BWpzy:focus {
    border-color: transparent;
    transition: background-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s;
    background-color: rgba(18, 18, 18, 0.08);
  }

  /* 斜杠 */
  .ilvYUM {
    height: 26px;
    min-width: 26px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 400;
    background-color: rgba(18, 18, 18, 0.08);
  }

  .bSzqaH {
    line-height: 26px;
    padding: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    background-color: rgba(255, 255, 255, 0.12);
    color: rgb(255, 255, 255);
    backdrop-filter: blur(20px);
    transition: background-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s,
      border-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s;
  }

  /* nav 3 */
  .hqIAuD {
    display: flex;
    margin: 0px;
    -webkit-box-align: center;
    align-items: center;
  }

  .gcKYtO {
    display: flex;
    flex-shrink: 0;
    margin: 0px;
    -webkit-box-align: center;
    align-items: center;
    column-gap: 0px;
  }
  @media (min-width: 1024px) {
    .gcKYtO {
      column-gap: 12px;
    }
  }
  /* 3-1 button */
  .eTVHax {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    column-gap: 8px;
    width: unset;
    min-width: 48px;
  }
  .bSIJEC {
    border-radius: 12px;
    padding: 0px 12px;
    position: relative;
    height: 48px;
    min-width: 48px;
    border-color: transparent;
    background-color: rgba(18, 18, 18, 0.04);
    transition: background-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s;
  }
  .bSIJEC:hover,
  .bSIJEC:focus {
    border-color: transparent;
    transition: background-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s;
    background-color: rgba(18, 18, 18, 0.08);
  }
  /* 3-2 */
  .cYNidN {
    border-radius: 12px;
    padding: 0px 12px;
    position: relative;
    height: 48px;
    min-width: 48px;
    border-color: transparent;
    background-color: rgba(18, 18, 18, 0.04);
    transition: background-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s;
  }
  /* 3-3 */
  .bPIJic {
    border-radius: 12px;
    padding: 0px px 12px;
    position: relative;
    height: 48px;
    min-width: 48px;
    border-color: transparent;
    background-color: rgba(18, 18, 18, 0.04);
    transition: background-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s;
  }
`;

const SelfPopover = styled(Popover)`
  .ant-popover-inner {
    padding: 0;
  }
`;

export default function Navbar() {
  const {
    isLogin,
    searchKey,
    chainId,
    balance,
    virtualMode,
    mintBalance,
    buyBalance,
  } = useSelector(
    (state) => ({
      isLogin: state.global.isLogin,
      searchKey: state.app.searchKey,
      chainId: state.chain.chainId,
      balance: state.chain.balance,
      virtualMode: state.app.virtualMode,
      mintBalance: state.chain.mintBalance,
      buyBalance: state.chain.buyBalance,
    }),
    shallowEqual
  );
  const [showLan, setShowLan] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);
  const [walletInfoOpen, setWalletInfoOpen] = useState(false);

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const handleWallet = (e) => {
    // dispatch(setWebaseModalOpen(false));
    console.log("isLogin", isLogin);
    if (isLogin) {
      setWalletInfoOpen(true);
      return;
    } else {
      setWalletInfoOpen(false);
      dispatch(setWalletModalOpen(true));
      return;
    }
  };
  const handleProfilePop = (e) => {
    setProfileOpen(true);
    // if (isLogin) {
    //   setProfileOpen(!profileOpen);
    //   return;
    // } else {
    //   dispatch(setWalletModalOpen(true));
    // }
  };
  const handleCreate = () => {};

  return (
    <>
      <ConnectWallet />

      <SelfDiv className="sc-185f7ffb-1 cfoEuU">
        <div className="mx-auto w-full max-w-[2560px] px-4 sm:px-8 xxl:px-16 sc-185f7ffb-2 biRvRv h-full">
          <nav className="Navbar--main items-center lg:py-1.5">
            <div className="sc-beff130f-0 sc-31935b1a-0 eqsTJA jBeUAx">
              <div className="media-greaterThanOrEqual-md">
                <a
                  className="no-underline cursor-pointer text-interactive-primary hover:text-interactive-primary-hover disabled:pointer-events-none disabled:opacity-40 flex items-center"
                  href="/"
                >
                  <div className="sc-beff130f-0 evBMDZ relative">
                    <span
                      style={{
                        boxSizing: "border-box",
                        display: "block",
                        overflow: "hidden",
                        width: "initial",
                        height: "initial",
                        background: "none",
                        opacity: 1,
                        border: 0,
                        margin: 0,
                        padding: 0,
                        position: "absolute",
                        inset: 0,
                      }}
                    >
                      <img
                        alt="OpenSea Logo"
                        src={openseaLogo}
                        decoding="async"
                        data-nimg="fill"
                        style={{
                          position: "absolute",
                          inset: 0,
                          boxSizing: "border-box",
                          padding: 0,
                          border: "none",
                          margin: "auto",
                          display: "block",
                          width: 0,
                          height: 0,
                          minWidth: "100%",
                          maxWidth: "100%",
                          minHeight: "100%",
                          maxHeight: "100%",
                        }}
                      />
                    </span>
                  </div>
                  <div
                    className="flex relative ml-2.5 mt-0.5"
                    data-testid="brand-name"
                  >
                    <Icon id={"ajtop"} />
                  </div>
                </a>
              </div>
              {isBrowser ? (
                <div className="flex media-greaterThanOrEqual-md">
                  <div className="sc-beff130f-0 eoiwrf">
                    <div
                      data-orientation="vertical"
                      role="none"
                      className="shrink-0 h-full w-[1px] mx-6 bg-white/20"
                    />
                  </div>
                  <div className="flex items-center gap-8">
                    <Link
                      className="no-underline cursor-pointer text-interactive-primary hover:text-interactive-primary-hover disabled:pointer-events-none disabled:opacity-40 sc-b92c3c2f-0 bYWTiF"
                      to="/collection"
                      aria-expanded="false"
                    >
                      <span
                        className="text-md leading-md font-semibold"
                        data-id="TextBody"
                      >
                        {t("Collection")}
                      </span>
                    </Link>

                    <Link
                      className="no-underline cursor-pointer text-interactive-primary hover:text-interactive-primary-hover disabled:pointer-events-none disabled:opacity-40 sc-b92c3c2f-0 bYWTiF"
                      aria-expanded="false"
                      to={"/courses"}
                    >
                      <span
                        className="text-md leading-md font-semibold"
                        data-id="TextBody"
                      >
                        {t("Courses")}
                      </span>
                    </Link>

                    <CreatePopover>
                      <div className="no-underline cursor-pointer text-interactive-primary hover:text-interactive-primary-hover disabled:pointer-events-none disabled:opacity-40 sc-b92c3c2f-0 bYWTiF">
                        <span
                          className="text-md leading-md font-semibold"
                          data-id="TextBody"
                        >
                          {t("create")}
                        </span>
                      </div>
                    </CreatePopover>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            {isBrowser ? (
              <MySearch />
            ) : (
              <Popover
                content={
                  <MySearch closeSearchOpen={() => setSearchOpen(false)} />
                }
                trigger={"click"}
                onOpenChange={(val) => setSearchOpen(val)}
                open={searchOpen}
              >
                <Icon id={"quickSearch"} />
              </Popover>
            )}
            <ul className="sc-3a663f94-0 hqIAuD min-w-0 sm:shrink-0">
              <div className="sc-3a663f94-1 gcKYtO media-greaterThanOrEqual-md">
                <div className="inline-flex">
                  <SelfPopover
                    trigger={"click"}
                    placement={isMobile ? "bottom" : "bottomLeft"}
                    onClick={handleWallet}
                    open={walletInfoOpen}
                    onOpenChange={(val) => setWalletInfoOpen(val)}
                    content={<WalletInfoPopover />}
                  >
                    <div
                      className="sc-beff130f-0 hksMfk"
                      style={{ zIndex: 190 }}
                      aria-expanded="false"
                    >
                      <button
                        className="inline-flex items-center border-0 bg-inherit font-[inherit] text-[100%] sc-ad61db5a-0 sc-74d7aeb3-0 bSIJEC eTVHax"
                        type="button"
                        data-id="UnstyledButton"
                      >
                        <svg
                          fill="currentColor"
                          height={24}
                          role="img"
                          viewBox="0 -960 960 960"
                          width={24}
                          xmlns="http://www.w3.org/2000/svg"
                          title="Wallet"
                        >
                          <title>Wallet</title>
                          <path d="M240-160q-66 0-113-47T80-320v-320q0-66 47-113t113-47h480q66 0 113 47t47 113v320q0 66-47 113t-113 47H240Zm0-480h480q22 0 42 5t38 16v-21q0-33-23.5-56.5T720-720H240q-33 0-56.5 23.5T160-640v21q18-11 38-16t42-5Zm-74 130 445 108q9 2 18 0t17-8l139-116q-11-15-28-24.5t-37-9.5H240q-26 0-45.5 13.5T166-510Z" />
                        </svg>
                        {isBrowser ? (
                          <div className="media-greaterThanOrEqual-lg">
                            <span
                              className=" text-md leading-md font-semibold h-6 overflow-x-hidden"
                              data-id="TextBody"
                            >
                              {isLogin
                                ? `${mintBalance} SXB | ${balance} ETH `
                                : `${t("login")}`}
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                      </button>
                    </div>
                  </SelfPopover>
                  <Popover
                    placement="bottomRight"
                    trigger={"click"}
                    open={profileOpen}
                    onOpenChange={(val) => setProfileOpen(val)}
                    onClick={handleProfilePop}
                    content={
                      showLan ? (
                        <ChooseLanguage
                          setShowLan={setShowLan}
                          closeProfileOpen={() => setProfileOpen(false)}
                        />
                      ) : (
                        <ProfilePopover
                          setShowLan={setShowLan}
                          closeProfileOpen={() => setProfileOpen(false)}
                        />
                      )
                    }
                  >
                    <div
                      className="no-underline cursor-pointer text-interactive-primary hover:text-interactive-primary-hover disabled:pointer-events-none disabled:opacity-40 sc-7856efab-0 iyVQSJ ml-3 flex items-center justify-center"
                      aria-expanded="false"
                    >
                      <div className="animate-pulse bg-component-gray-3 rounded-full h-7 w-7">
                        <Icon id={"profile"} />
                      </div>
                    </div>
                  </Popover>
                </div>
              </div>
              {isBrowser ? "" : <NavBarMenu />}
              <div className="flex items-center gap-4" />
            </ul>
          </nav>
        </div>
      </SelfDiv>
    </>
  );
}

function NavBarMenu() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const navigateToPath = (path) => {
    navigate(path);
    setOpen(false);
  };
  return (
    <>
      <Drawer
        onClose={onClose}
        open={open}
        height={"90%"}
        placement="bottom"
        style={{ bottom: 0 }}
      >
        <ul
          style={{ display: "flex", margin: 0, flex: "1 0 0%" }}
          className="flex flex-col"
        >
          <li
            className="sc-886d585-0 cMGSvU"
            onClick={() => navigateToPath("/create")}
          >
            <div className="flex flex-col justify-center relative w-full">
              <button
                className="inline-flex items-center border-0 bg-inherit font-[inherit] text-[100%] justify-between"
                type="button"
              >
                <div className="flex items-center">
                  <Icon id={"createApp"}></Icon>
                  <span style={{ fontSize: "17px" }}>Create</span>
                </div>

                <Icon id={"rightRow"} />
              </button>
            </div>
          </li>
          <li
            className="sc-886d585-0 cMGSvU my-2"
            onClick={() => navigateToPath("/collection")}
          >
            <div className="flex flex-col justify-center relative w-full">
              <button
                className="inline-flex items-center border-0 bg-inherit font-[inherit] text-[100%] justify-between"
                type="button"
              >
                <div className="flex items-center">
                  <Icon
                    id={"collection"}
                    width="35"
                    height="35"
                    style={{ margin: "7px" }}
                  ></Icon>
                  <span style={{ fontSize: "17px" }}>Collection</span>
                </div>

                <Icon id={"rightRow"} />
              </button>
            </div>
          </li>
        </ul>
      </Drawer>
      <div
        className="flex flex-col justify-center items-center"
        style={{ marginLeft: "9px" }}
      >
        <div className="media-greaterThanOrEqual-lg">
          <button
            className="inline-flex items-center border-0 bg-inherit font-[inherit] text-[100%] sc-ad61db5a-0 bSIJEC"
            type="button"
            data-id="UnstyledButton"
            onClick={showDrawer}
          >
            <Icon id={"menu"} />
          </button>
        </div>
      </div>
    </>
  );
}
