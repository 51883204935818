import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// 引入语言文件
import enTranslation from "locales/en/translation.json";
import zhTranslation from "locales/zh/translation.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // 通过use方法将i18next传递给react-i18next
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      zh: {
        translation: zhTranslation,
      },
    },
    lng: "en", // 默认语言
    fallbackLng: "en", // 如果当前语言不可用，则使用该语言
    interpolation: {
      escapeValue: false, // 不需要对结果进行转义
    },
  });

export default i18n;
