import { merge } from "lodash";
import configs from "config";
import { Axios } from "./axios";
import {
  ContentTypeEnum,
  RequestCodeEnum,
  RequestMethodsEnum,
} from "enums/requestEnums";
import { clearAuthInfo, getToken } from "../auth";
import NProgress from "nprogress";
import axios from "axios";
import { PageEnum } from "enums/pageEnum";

const axiosHooks = {
  requestInterceptorsHook(config) {
    NProgress.start();
    const { withToken, isParamsToData } = config.requestOptions;
    const params = config.params || {};
    const headers = config.headers || {};
    if (withToken) {
      const token = getToken();
      headers.token = token;
    }
    if (
      isParamsToData &&
      !Reflect.has(config, "data") &&
      config.method?.toUpperCase() === RequestMethodsEnum.POST
    ) {
      config.data = params;
      config.params = {};
    }
    config.headers = headers;
    return config;
  },
  requestInterceptorsCatchHook(err) {
    NProgress.done();
    return err;
  },
  async responseInterceptorsHook(response) {
    NProgress.done();
    const { isTransformResponse, isReturnDefaultResponse } =
      response.config.requestOptions;
    if (
      response.config.url == "api/cust/downloadFailedUsingJson" ||
      response.config.url == "api/clues/downloadFailedUsingJson" ||
      response.config.url == "api/clues/downloadByUserTemplate" ||
      response.config.url == "api/cust/downloadbycusttemplate" ||
      response.config.url == "api/clues/downloadByUserTemplat" ||
      response.config.url == "api/cust/download" ||
      response.config.url == "api/contact/downloadtemplate" ||
      response.config.url == "api/clues/downloadtemplate" ||
      response.config.url == "api/clues/download" ||
      response.config.url == "api/cust/downloadtemplate"
    ) {
      return response;
    }
    if (isReturnDefaultResponse) {
      return response;
    }
    if (!isTransformResponse) {
      return response.data;
    }
    // return response.data;
    const { code, data, show, msg } = response.data;
    switch (code) {
      case RequestCodeEnum.SUCCESS:
        return data;
      case RequestCodeEnum.PARAMS_TYPE_ERROR:
      case RequestCodeEnum.PARAMS_VALID_ERROR:
      case RequestCodeEnum.REQUEST_METHOD_ERROR:
      case RequestCodeEnum.ASSERT_ARGUMENT_ERROR:
      case RequestCodeEnum.ASSERT_MYBATIS_ERROR:
      case RequestCodeEnum.LOGIN_ACCOUNT_ERROR:
      case RequestCodeEnum.LOGIN_DISABLE_ERROR:
      case RequestCodeEnum.NO_PERMISSTION:
      case RequestCodeEnum.FAILED:
      case RequestCodeEnum.SYSTEM_ERROR:
        return Promise.reject(data);
      case RequestCodeEnum.TOKEN_INVALID:
      case RequestCodeEnum.TOKEN_EMPTY:
        clearAuthInfo();
        return Promise.reject();
      default:
        return data;
    }
  },
  responseInterceptorsCatchHook(error) {
    NProgress.done();
    if (error.code !== axios.ERR_CANCELED) {
      console.log("responseInterceptorsCatchHook(error)", error);
    }
    return Promise.reject(error);
  },
};

const defaultOptions = {
  timeout: configs.timeout,
  baseURL: configs.baseUrl,
  headers: { "Content-Type": ContentTypeEnum.JSON, version: configs.version },
  axiosHooks: axiosHooks,
  requestOptions: {
    isParamsToData: true,
    isReturnDefaultResponse: false,
    isTransformResponse: true,
    urlPrefix: configs.urlPrefix,
    ignoreCancelToken: false,
    withToken: true,
    isOpenRetry: true,
    retryCount: 2,
  },
};

const uploadOptions = {
  timeout: configs.timeout,
  baseURL: configs.baseUrl,
  headers: {
    "Content-Type": ContentTypeEnum.FORM_DATA,
    version: configs.version,
  },
  axiosHooks: axiosHooks,
  requestOptions: {
    isParamsToData: true,
    isReturnDefaultResponse: false,
    isTransformResponse: true,
    urlPrefix: configs.urlPrefix,
    ignoreCancelToken: false,
    withToken: true,
    isOpenRetry: true,
    retryCount: 2,
  },
};

function createAxios(opt) {
  return new Axios(merge(defaultOptions, opt || {}));
}

function createUploadAxios(opt) {
  return new Axios(merge(uploadOptions, opt || {}));
}

export const requestUpload = createUploadAxios();
export const request = createAxios();
