import React, { useEffect } from "react";
import { HashRouter as Router } from "react-router-dom";
import { Route, Routes } from "react-router-dom";

import MainLayout from "layouts/MainLayout";
import HomePage from "pages/HomePage";

import "./App.css";
import WeBaseModal from "components/ConnectWallet/components/WeBaseModal";

export default function App2() {
  return (
    <>
      <WeBaseModal />
      <Router>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route index element={<HomePage />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}
