import axios from "axios";

const service = axios.create({
  baseURL: "http://localhost:8080",
  timeout: 5000,
});

export const service2 = axios.create({
  baseURL: "http://1.92.92.61:5002",
  timeout: 5000,
});

export default service;
