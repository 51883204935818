// src/features/counter/counterSlice.js
import { createSlice } from "@reduxjs/toolkit";

export const adminSlice = createSlice({
  name: "admin",
  initialState: {
    reviewAppDrawer: false,
    appChainInfoList: [], //软件安装教程链关联地址
    reviewAppList: [],
  },
  reducers: {
    setReviewAppDrawer(state, { payload }) {
      state.reviewAppDrawer = payload;
    },
    setAppChainInfoList(state, { payload }) {
      state.appChainInfoList = payload;
    },
    setReviewAppList(state, { payload }) {
      state.reviewAppList = payload;
    },
  },
});

export const { setReviewAppDrawer, setAppChainInfoList, setReviewAppList } =
  adminSlice.actions;

export default adminSlice.reducer;
