import React from "react";
import { useNavigate } from "react-router-dom";

import { Button, Popover } from "antd";

import styled from "styled-components";

const CreateDiv = styled.div`
  margin: 7px 12px;
`;

export default function CreatePopover({ children }) {
  const navigate = useNavigate();
  const content = (
    <div>
      <CreateDiv onClick={() => handleNavigate("/create")}>
        创建安装教程
      </CreateDiv>
      <CreateDiv>创建藏品集</CreateDiv>
      <CreateDiv>创建课程集</CreateDiv>
    </div>
  );
  const handleNavigate = (to) => {
    navigate(to);
  };
  return (
    <Popover content={content} trigger={"click"}>
      {children}
    </Popover>
  );
}
