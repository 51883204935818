const config = {
  terminal: 1, //终端
  title: "后台管理系统", //网站默认标题
  version: "1.3.3", //版本号
  baseUrl: "https://soul.a-j.top", //请求接口域名
  urlPrefix: "api", //请求默认前缀
  timeout: 10 * 1000, //请求超时时长
};

// http://47.102.151.27:8080
// http://192.168.5.157:8080
// http://192.168.0.103:8080
// https://a-j.top

export default config;
