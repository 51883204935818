// src/features/counter/counterSlice.js
import { createSlice } from "@reduxjs/toolkit";

export const appSlice = createSlice({
  name: "app",
  initialState: {
    modalOpen: false,
    chainModalOpen: false,

    modalCategory: "",
    softwareImg: "",
    imageArr: [],
    commonOptions: [],
    options: [],
    categoryItem: "",
    language: "zh",

    appChooseModal: 0, // option modal
    searchInfo: "",
    searchKey: "",
    currentAppId: "",
    virtualMode: 0,
    softwareChoose: {
      url: "",
      name: "",
      ver: "",
    },
    bandChoose: {
      url: "",
      name: "",
      bandName: "",
      seriesName: "",
      ver: "",
    },
    osChoose: {
      url: "",
      name: "",
      ver: "",
    },
    dockerChoose: {
      url: "",
      ver: "",
      name: "",
    },
    vmChoose: {
      name: "",
      ver: "",
      url: "",
    },
    vosChoose: {
      name: "",
      ver: "",
      url: "",
    },
    vdockerChoose: {
      name: "",
      ver: "",
      url: "",
    },
    osObjConstant: {
      CentOS: {
        url: "https://mmbiz.qpic.cn/sz_mmbiz_png/gLMzPleWPibQlAzGLOJWq5Y3ZghR8LMk2TyWO6YVdIuQbI4x6mzEy67Qr7cWh8dVOYqFZyXpy6Qq8hWArPJficsg/0?wx_fmt=png&from=appmsg",
        verList: ["7.3", "7.6", "8.2"],
      },
      "Windows 11": {
        url: "https://mmbiz.qpic.cn/sz_mmbiz_png/gLMzPleWPibQlAzGLOJWq5Y3ZghR8LMk2aKa291SynNRQN30xgZdVFKkfb2R2Guf4Z7icgiasGyfibicPYw4ZgkEVYg/0?wx_fmt=png&from=appmsg",
        verList: ["11"],
      },
      "Windows 10": {
        url: "https://mmbiz.qpic.cn/sz_mmbiz_jpg/gLMzPleWPibQlAzGLOJWq5Y3ZghR8LMk2qiatQEjicK1V2bZnvWicRGjf4TgOU3WWNVAW0pMBiaURVRWYaGUTSsuDicQ/0?wx_fmt=jpeg&from=appmsg",
        verList: ["10"],
      },
      MacOS: {
        url: "https://mmbiz.qpic.cn/sz_mmbiz_jpg/gLMzPleWPibQlAzGLOJWq5Y3ZghR8LMk2qdIObQlbfHFz8ibX2nZlpsxsHH5rxdsorKuW9KzSIfaqicjfUibkEicd9Q/0?wx_fmt=jpeg&from=appmsg",
        verList: ["11"],
      },
    },
  },
  reducers: {
    setImageArr(state, { payload }) {
      state.imageArr = payload;
    },
    setSoftwareImg(state, { payload }) {
      state.softwareImg = payload;
    },
    setModalCategory(state, { payload }) {
      state.modalCategory = payload;
    },
    setSearchInfo(state, { payload }) {
      state.searchInfo = payload;
      state.currentAppId = payload?.appId;
    },
    setOptions(state, { payload }) {
      state.options = payload;
    },
    setSearchKey(state, { payload }) {
      state.searchKey = payload;
    },
    setModalOpen(state, { payload }) {
      state.modalOpen = payload;
    },
    setCategoryItem(state, { payload }) {
      state.categoryItem = payload;
    },
    setLanguage(state, { payload }) {
      state.language = payload;
    },
    setChainModalOpen(state, { payload }) {
      state.chainModalOpen = payload;
    },

    setAppChooseModal(state, { payload }) {
      state.appChooseModal = payload;
    },
    setSoftwareChoose(state, { payload }) {
      state.softwareChoose = payload;
    },
    setBandChoose(state, { payload }) {
      state.bandChoose = payload;
    },
    setOsChoose(state, { payload }) {
      state.osChoose = payload;
    },
    setDockerChoose(state, { payload }) {
      state.dockerChoose = payload;
    },
    setVmChoose(state, { payload }) {
      state.vmChoose = payload;
    },
    setVosChoose(state, { payload }) {
      state.vosChoose = payload;
    },
    setVdockerChoose(state, { payload }) {
      state.vdockerChoose = payload;
    },
    setVirtualMode(state, { payload }) {
      state.virtualMode = payload;
    },
  },
});

export const {
  setImageArr,
  setSoftwareImg,
  setModalCategory,
  setSearchInfo,
  setOptions,
  setSearchKey,
  setModalOpen,
  setCategoryItem,
  setLanguage,
  setChainModalOpen,

  setAppChooseModal,
  setSoftwareChoose,
  setBandChoose,
  setOsChoose,
  setDockerChoose,
  setVmChoose,
  setVosChoose,
  setVdockerChoose,
  setVirtualMode,
} = appSlice.actions;

export default appSlice.reducer;
