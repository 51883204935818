// Assuming '@/enums/cacheEnums', '@/router', '@/stores/modules/multipleTabs', and '@/stores/modules/user'
// are already JavaScript modules that have been converted or have equivalent JavaScript functionality.

import { TOKEN_KEY } from "enums/cacheEnums";
import cache from "./cache";

export function getToken() {
  return cache.get(TOKEN_KEY);
}

export function clearAuthInfo() {
  cache.remove(TOKEN_KEY);
}
