import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import Icon from "components/Icon";
import { Popover } from "antd";

import QRCode from "qrcode.react";

import styled from "styled-components";
import { truncateMiddle } from "utils/strUtil";
import ChainList from "./ChainList";
import cache from "utils/cache";
import { TOKEN_KEY } from "enums/cacheEnums";
import { setChainId, setChainType } from "store/module/chain";
import { METAMASK, WEBASE } from "enums/chainEnums";

const SelfDiv = styled.div`
  width: 400px;
`;

let chainOptions = [
  {
    name: "Sepolia",
    value: 11155111,
    iconId: "sepolia",
    width: 20,
    height: 20,
  },
  {
    name: "WeBase",
    value: -100,
    iconId: "webase",
    width: 39,
    height: 39,
  },
];
export default function WalletInfoPopover() {
  const { account, chainId, mintBalance } = useSelector(
    (state) => ({
      account: state.chain.account,
      chainId: state.chain.chainId,
      mintBalance: state.chain.mintBalance,
    }),
    shallowEqual
  );
  const [chain, setChain] = useState();
  const [chainOptionOpen, setChainOptionOpen] = useState(false);

  const dispatch = useDispatch();
  const changeChain = (val) => {
    console.log("change Chain one time", val);
    setChainOptionOpen(false);
    if (val) {
    } else {
      return;
    }
    if (val == -100) {
      dispatch(setChainType(WEBASE));
      dispatch(setChainId(val));
      // localStorage.setItem("chain_type", WEBASE);
    } else {
      dispatch(setChainType(METAMASK));
      dispatch(setChainId(val));
      // localStorage.setItem("chain_type", METAMASK);
    }
  };
  useEffect(() => {
    console.log(`walletinfopopover: ${chainId}`);
    if (chainId) {
      let chainInfo = chainOptions.find((e) => e.value == chainId);
      setChain(chainInfo);
    }
  }, [chainId]);
  return (
    <SelfDiv className="relative flex flex-col">
      <div className="flex justify-between p-3 ">
        <div className="flex items-center">
          <Popover
            content={<QRCode value={account} renderAs="canvas" />}
            placement="bottom"
          >
            <button
              type="button"
              className="inline-flex items-center justify-center whitespace-nowrap transition duration-200 text-primary gap-2 disabled:pointer-events-none disabled:opacity-40 h-12 rounded-xl text-md bg-transparent p-3 font-normal hover:bg-component-gray-2"
              data-id="Button"
            >
              <Icon id={"metamask"} width="27" height="27" />
              {truncateMiddle(account, 6, 4)}
            </button>
          </Popover>
        </div>
        <div>
          <Popover
            content={
              <ChainList options={chainOptions} changeChain={changeChain} />
            }
            onOpenChange={(val) => setChainOptionOpen(val)}
            open={chainOptionOpen}
            placement="bottomRight"
            trigger={"click"}
          >
            <button
              className="font-[inherit] text-[100%] border-level-2 cursor-pointer duration-[250ms] inline-flex items-center h-12 rounded-medium border-0 p-3 py-1 pr-2 !shadow-none hover:bg-component-gray-2 active:bg-component-gray-3 w-auto bg-transparent"
              data-id="Item"
              type="button"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div className="flex flex-col justify-center">
                {chain ? (
                  <Icon
                    id={chain?.iconId}
                    width={chain.width}
                    height={chain.height}
                  />
                ) : (
                  ""
                )}
              </div>
              <input type="hidden" defaultValue="Polygon" />
              <div
                className="flex flex-col justify-center order-3 overflow-hidden flex-auto items-start self-stretch mr-0"
                data-id="ItemContent"
              >
                <span
                  className="font-semibold lg:text-md text-primary text-md"
                  data-id="ItemTitle"
                />
              </div>
              <div className="flex flex-col order-4 overflow-hidden text-right flex-[0_0_auto] justify-center self-stretch max-w-none">
                <div className="flex flex-col justify-center">
                  <svg
                    className="fill-current duration-[250ms] text-primary transition-[transform,background-color] ease-in-out ml-2"
                    height={24}
                    role="img"
                    viewBox="0 -960 960 960"
                    width={24}
                    xmlns="http://www.w3.org/2000/svg"
                    aria-label="展开"
                  >
                    <title>Expand more</title>
                    <path d="M480-345 240-585l56-56 184 184 184-184 56 56-240 240Z" />
                  </svg>
                </div>
              </div>
            </button>
          </Popover>
        </div>
      </div>
      <div className="relative flex flex-1 flex-col overflow-auto rounded-t-large">
        <div
          className="sticky top-0 z-0 h-0 shrink-0 overflow-hidden opacity-0 transition-[opacity,height] duration-200 ease-out"
          style={{ height: 88, opacity: 1, transform: "scale(1)" }}
        >
          <div>
            <div className="flex justify-between px-6 pb-5 pt-2 fade-in">
              <div>
                <div className="text-heading-md font-semibold flex flex-row items-center">
                  <Icon id={"coin"}></Icon>
                  {mintBalance} SXB
                </div>
                <div className="text-secondary">钱包余额</div>
              </div>
              <div data-state="closed">
                <button
                  type="button"
                  className="inline-flex items-center justify-center whitespace-nowrap transition duration-200 font-semibold bg-component-gray-2 text-primary hover:bg-component-gray-3 gap-2 py-3 px-3 disabled:pointer-events-none disabled:opacity-40 rounded-xl text-md size-14"
                  data-id="Button"
                  aria-label="添加资金"
                  aria-expanded="false"
                >
                  <div
                    className="flex pointer-events-none max-h-6"
                    aria-hidden="true"
                  >
                    <svg
                      className="fill-current"
                      height={24}
                      role="img"
                      viewBox="0 -960 960 960"
                      width={24}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <title>Add</title>
                      <path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" />
                    </svg>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="relative z-10 flex flex-1 flex-col rounded-t-large">
          <div className="flex-1 rounded-t-large bg-elevation-2 shadow-elevation-1-subtle">
            <div className="rounded-t-large pb-3 flex-1">
              <div className="flex flex-col items-center justify-center space-y-4 px-14 py-11 text-heading-sm font-semibold">
                <img alt="" src="/static/images/wallet/empty-state-cards.png" />
                <p className="max-w-[200px] text-center">
                  Fund your wallet to purchase NFTs
                </p>
                <button
                  type="button"
                  className="inline-flex items-center justify-center whitespace-nowrap transition duration-200 font-semibold bg-blue-3 text-white hover:bg-blue-2 gap-2 px-4 py-3 disabled:pointer-events-none disabled:opacity-40 h-12 rounded-xl text-md"
                  data-id="Button"
                >
                  Add Funds with Cards
                </button>
              </div>
            </div>
            <div
              style={{
                position: "fixed",
                top: 1,
                left: 1,
                width: 1,
                height: 0,
                padding: 0,
                margin: "-1px",
                overflow: "hidden",
                clip: "rect(0, 0, 0, 0)",
                whiteSpace: "nowrap",
                borderWidth: 0,
                display: "none",
              }}
            />
            <div
              style={{
                position: "fixed",
                top: 1,
                left: 1,
                width: 1,
                height: 0,
                padding: 0,
                margin: "-1px",
                overflow: "hidden",
                clip: "rect(0, 0, 0, 0)",
                whiteSpace: "nowrap",
                borderWidth: 0,
                display: "none",
              }}
            />
          </div>
        </div>
      </div>
      <nav className="bg-elevation-2" role="navigation">
        <ul className="flex h-[86px]" role="menu">
          <li
            className="flex h-full flex-1 flex-col border-t border-level-1"
            role="menuitem"
          >
            <div
              className="flex flex-1 flex-col items-center justify-center text-xs text-primary"
              href="/zh-CN"
            >
              <span className="inline-block">
                <svg
                  className="fill-current"
                  height={28}
                  role="img"
                  viewBox="0 -960 960 960"
                  width={28}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Paid</title>
                  <path d="M444-200h70v-50q50-9 86-39t36-89q0-42-24-77t-96-61q-60-20-83-35t-23-41q0-26 18.5-41t53.5-15q32 0 50 15.5t26 38.5l64-26q-11-35-40.5-61T516-710v-50h-70v50q-50 11-78 44t-28 74q0 47 27.5 76t86.5 50q63 23 87.5 41t24.5 47q0 33-23.5 48.5T486-314q-33 0-58.5-20.5T390-396l-66 26q14 48 43.5 77.5T444-252v52Zm36 120q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z" />
                </svg>
              </span>
              加密
            </div>
          </li>
          <li
            className="flex h-full flex-1 flex-col border-t border-level-1"
            role="menuitem"
          >
            <div
              className="flex flex-1 flex-col items-center justify-center text-xs text-secondary"
              href="/zh-CN/transactions"
            >
              <span className="inline-block">
                <svg
                  className="fill-current"
                  height={28}
                  role="img"
                  viewBox="0 -960 960 960"
                  width={28}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>View List</title>
                  <path d="M360-240h440v-107H360v107ZM160-613h120v-107H160v107Zm0 187h120v-107H160v107Zm0 186h120v-107H160v107Zm200-186h440v-107H360v107Zm0-187h440v-107H360v107ZM160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Z" />
                </svg>
              </span>
              交易
            </div>
          </li>
          <li
            className="flex h-full flex-1 flex-col border-t border-level-1"
            role="menuitem"
          >
            <div
              className="flex flex-1 flex-col items-center justify-center text-xs text-secondary"
              href="/zh-CN/settings"
            >
              <span className="inline-block">
                <svg
                  className="fill-current"
                  height={28}
                  role="img"
                  viewBox="0 -960 960 960"
                  width={28}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Settings</title>
                  <path d="m370-80-16-128q-13-5-24.5-12T307-235l-119 50L78-375l103-78q-1-7-1-13.5v-27q0-6.5 1-13.5L78-585l110-190 119 50q11-8 23-15t24-12l16-128h220l16 128q13 5 24.5 12t22.5 15l119-50 110 190-103 78q1 7 1 13.5v27q0 6.5-2 13.5l103 78-110 190-118-50q-11 8-23 15t-24 12L590-80H370Zm70-80h79l14-106q31-8 57.5-23.5T639-327l99 41 39-68-86-65q5-14 7-29.5t2-31.5q0-16-2-31.5t-7-29.5l86-65-39-68-99 42q-22-23-48.5-38.5T533-694l-13-106h-79l-14 106q-31 8-57.5 23.5T321-633l-99-41-39 68 86 64q-5 15-7 30t-2 32q0 16 2 31t7 30l-86 65 39 68 99-42q22 23 48.5 38.5T427-266l13 106Zm42-180q58 0 99-41t41-99q0-58-41-99t-99-41q-59 0-99.5 41T342-480q0 58 40.5 99t99.5 41Zm-2-140Z" />
                </svg>
              </span>
              设置
            </div>
          </li>
        </ul>
      </nav>
    </SelfDiv>
  );
}
